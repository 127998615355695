import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import logo from 'src/assets/images/logo.svg'
import style from './header.module.scss'

const Header = ({ siteTitle }) => (
  <header className={style.header}>
    <div className={`u-wrapper ${style.wrapper}`}>
      <Link to="/">
        <img className={style.header__logo} src={logo} alt={siteTitle} />
      </Link>

      <div className={style.header__quote}>
        <blockquote>
          Il ne peut plus rien nous arriver d'affreux maintenant
        </blockquote>
        <cite>– Red is dead, La cité de la peur</cite>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
