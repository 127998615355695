import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import 'src/assets/scss/app.scss'
import Footer from 'src/components/footer/footer'
import Header from 'src/components/header/header'

const Layout = ({ children, isMini }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="area--page">
        <div className={`u-wrapper${isMini ? ' u-wrapper--mini' : ''}`}>
          {children}
        </div>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
