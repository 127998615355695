import { graphql } from 'gatsby'
import React from 'react'
import Layout from 'src/components/layout/layout'
import Page from 'src/components/page/page'
import SEO from 'src/components/seo'

const PageTemplate = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { html, frontmatter } = markdownRemark
  return (
    <Layout isMini={true}>
      <SEO title={frontmatter.title} description={frontmatter.description} />
      <Page content={html} />
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
